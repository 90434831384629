import React from 'react'

const QuarterTable = () => {
  return (
    <table className="min-w-full bg-white border border-gray-200 rounded-lg">
        <thead className="bg-gray-50">
            <tr>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider border-b border-gray-200">
                    2023 Q1
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider border-b border-gray-200">
                    2023 Q2
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider border-b border-gray-200">
                    2023 Q3
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider border-b border-gray-200">
                    2023 Q4
                </th>
            </tr>
        </thead>
        <tbody>
            <tr className="bg-white hover:bg-gray-50">
                <td className="py-3 px-4 text-sm text-gray-700 border-b border-gray-200">
                    N.A.
                </td>
               <td className="py-3 px-4 text-sm text-gray-700 border-b border-gray-200">
                    N.A. 
                </td>
                <td className="py-3 px-4 text-sm text-gray-700 border-b border-gray-200">
                    N.A. 
                </td>
                <td className="py-3 px-4 text-sm text-gray-700 border-b border-gray-200">
                    N.A. 
                </td>
            </tr>
        </tbody>
    </table>
  )
}

export default QuarterTable